<template>
  <list-layout v-if="thead.length" ref="listLayout"
               :filter-form="form"
               :thead="thead"
               :on-fetch="getList">
    <template slot="filter">
      <my-filter :filter-change="onFilter" :export-data="form" />
    </template>
    <template slot="item_finished_count" slot-scope="{row}">
      {{row.finished_count}} <el-button @click="open(row.user_id)"
                                         type="text" size="medium" style="margin-left: 5px;">查看</el-button>
    </template>
    <el-dialog title="查看详情" :visible="detailDialog.visible" width="600px" class="dialog-vertical"
               @close="cancel" close-on-click-modal>
      <div v-loading="detailDialog.loading">
        <h3>所含课程数：{{detailDialog.detail && detailDialog.detail.chapters_count || 0}} 章节 共 {{detailDialog.detail && detailDialog.detail.reses_count || 0}} 门课程</h3>
        <div class="medium-form">
          <div class="el-form-item">
            <div class="el-form-item__content">
              <div class="info">
                <i class="el-icon-info" />单门课程的学习数据更新频率为实时，与章节数据更新时间存在偏差。
              </div>
            </div>
          </div>
        </div>

        <el-table border :data="detailDialog.detail ? detailDialog.detail.records : []"
                  :max-height="detailDialog.detail && detailDialog.detail.records.length && detailDialog.detail.records.length < 5 ? 'auto' : '270px'"
                  style="width: 100%;">
          <el-table-column label="课程名称" prop="res_title" :min-width="200" show-overflow-tooltip />
          <el-table-column label="类型" prop="res_type_text" />
          <el-table-column label="学习时长">
            <template slot-scope="{row}">{{row.total_minutes}} 分钟</template>
          </el-table-column>
          <el-table-column label="学习进度">
            <template slot-scope="{row}">{{row.progress}} %</template>
          </el-table-column>
        </el-table>
      </div>
    </el-dialog>
  </list-layout>
</template>

<script>
  import ListLayout from "@/base/layout/ListLayout";
  import {getStudentLearnDetail, getStudents} from "@/modules/online-classroom/api/course-manage/student-list";
  import MyFilter from "../../components/course-manage/student-list/Filter";
  export default {
    components: {MyFilter, ListLayout},
    data() {
      return {
        form: {
          "course_id": this.$route.query.courseId,
          "page": 1,
          "page_size": 15,
          "nickname": "",
          "create_start_time": -1,
          "create-end_time": -1
        },
        thead: [],
        detailDialog: {
          visible: false,
          detail: null,
          postData: {},
          loading: true,
        }
      }
    },
    watch: {
      'detailDialog.visible' (newValue) {
        this.detailDialog.loading = newValue
        if (newValue) {
          getStudentLearnDetail(this.detailDialog.postData).then(res => {
            this.detailDialog.detail = res.data;
            this.detailDialog.loading = false;
          }).catch(err => {
            this.detailDialog.loading = false;
          })
        } else {
          this.detailDialog.detail = null
        }
      }
    },
    created() {
      let thead = [ {label: '会员名称', prop: 'nickname', minWidth: 100},
        {label: '职务', prop: 'title_name', minWidth: 220},
        {label: '状态', prop: 'status_text', minWidth: 100},
        {label: '累计学习时长', prop: 'learn_minutes', minWidth: 150,
          info: '音视频：用户在课程中播放视频的时长；\n文档：用户在课程页面的停留时长'},
        {label: '有效学习时长', prop: 'effective_learn_minutes', minWidth: 150,
          info: '音视频：音视频文件的时长；\n' + '文档：不统计有效时长'},
        {label: '学习进度', prop: 'progress', minWidth: 100,
          info: '文档：浏览位置/文档详情总长度；\n' +
            '音视频：最大的播放时间点/总时长，最大播放时间点包括拖拽操作'},
        {label: '开始时间', prop: 'create_time', minWidth: 200},
        {label: '完成时间', prop: 'finished_time', minWidth: 200}]
      if (this.$route.query.type === '章节课程') {
        thead.splice(5, 1, {
          label: '课程完成数', prop: 'finished_count', minWidth: 130,
          info: '对应用户完成该章节内单课学习的数量，每天更新昨日数据', type: 'slot'
        })
      }
      this.thead = thead
    },
    methods: {
      getList(data) {
        return getStudents(data)
      },
      // 筛选回调
      onFilter(filterObj) {
        Object.assign(this.form, filterObj, { page: 1 });
        this.$refs.listLayout.getList();
      },
      cancel() {
        this.detailDialog.visible = false
      },
      open(user_id) {
        this.detailDialog.postData = {user_id, course_id: this.form.course_id}
        this.detailDialog.visible = true
      }
    },
  }
</script>

<style lang="scss" scoped>
::v-deep.el-table {
  border-bottom: 1px solid $border-color;
}

</style>
