import {api} from "@/base/utils/request";

export const getStudents = (data) => {
  return api({
    url: '/admin/classroom/course/students',
    method: 'post',
    data
  })
}

export const getStudentLearnDetail = (data) => {
  return api({
    url: '/admin/classroom/course/learnDetail',
    method: 'post',
    data
  })
}
