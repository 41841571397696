<template>
  <LayoutFilter :onFilter="onFilter" :onReset="onReset" :toggle-collapse-enable="false">
    <el-form :model="form" :rules="rules" ref="ruleForm" label-width="70px" size="medium" class="filter-from">
      <div class="filter-item">
        <el-form-item label="会员名称：" prop="nickname">
          <el-input v-model="form.nickname" placeholder="请输入" clearable />
        </el-form-item>
<!--        <el-form-item label="学习状态：" prop="">-->
<!--&lt;!&ndash;          <el-select v-model="form.category_id">&ndash;&gt;-->
<!--&lt;!&ndash;            <el-option v-for="op in categoryArr" :key="op.id" :label="op.name" :value="op.id"></el-option>&ndash;&gt;-->
<!--&lt;!&ndash;          </el-select>&ndash;&gt;-->
<!--        </el-form-item>-->
      </div>
      <div class="filter-item">
        <DatePeriodPicker label="创建时间：" :start-time.sync="form.create_start_time"
          start-prop="create_start_time" :end-time.sync="form.create_end_time" end-prop="create_end_time" />
      </div>
    </el-form>
  </LayoutFilter>
</template>

<script>
import LayoutFilter from "@/base/layout/LayoutFilter";
import DatePeriodPicker from "@/base/components/Form/DatePeriodPicker";
import { category } from "../../../api/course-manage/index";
export default {
  props: {
    filterChange: {
      required: true,
      type: Function,
    },
    //导出要用的数据
    exportData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      form: {
        create_start_time: -1, //创建开始时间
        create_end_time: -1, //创建结束时间
        nickname: "", // 课程名称搜索
      },
      rules: {},
      //课程分类数据
      categoryArr: [{ name: "全部", id: "" }],
      //课程类型选项数据
      typeArr: [
        { name: "全部", id: -1 },
        { name: "独立课程", id: 0 },
        { name: "章节课程", id: 1 },
      ],
      //显示范围选项数据
      showTypeArr: [
        { name: "全部", id: -1 },
        { name: "全部学员", id: 1 },
        { name: "部分学员", id: 2 },
        { name: "不显示", id: 0 },
      ],
    };
  },
  methods: {
    //重置
    onReset() {
      this.$refs["ruleForm"].resetFields();
      return this.onFilter();
    },
    //筛选
    onFilter() {
      return this.filterChange(Object.assign({}, this.form));
    },
    //导出事件
    onExport() {},
    //获取分类选项数据
    getCategoryArr() {
      category()
        .then((res) => {
          const { data } = res;
          this.categoryArr.push(...data);
        })
        .catch(() => {});
    },
  },
  created() {
    // this.getCategoryArr();
  },
  components: {
    LayoutFilter,
    DatePeriodPicker,
  },
};
</script>

<style lang="scss" scoped>
</style>
